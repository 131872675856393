* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  background-color: #fff;
  color: #555555;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-rendering: optimizeLegibility;
}
body {
  font-family: 'Lato', sans-serif;
  color: #555555;
  font-weight: 300;
}

header {
  background-image: linear-gradient(
      rgba(26, 37, 53, 0.7),
      rgba(10, 28, 49, 0.7)
    ),
    url(images/header.jpg);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

h1,
h2 {
  color: #fff;
  font-weight: 300;
}
h1 {
  font-size: 200%;
}
h2 {
  font-size: 130%;
}

h1,
h3,
h4,
h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  word-spacing: 4px;
}
h3 {
  font-size: 180%;
  margin-bottom: 30px;
  text-align: center;
  color: #007bff;
}

h3::after {
  display: block;
  height: 4px;
  background-color: #007bff;
  width: 80px;
  content: ' ';
  margin-top: 30px;
  margin: 0 auto;
}
h4 {
  font-size: 105%;
}

h5 {
  font-size: 90%;
  font-weight: 600;
  margin-bottom: 20px;
}

.process-card {
  text-align: center;
}

.process-icon {
  border: 5px solid #007bff;
  background-color: #007bff;
  text-align: center;
}

.our-process {
  margin-top: 50px;
}
.our-process h4 {
  text-align: center;
  vertical-align: baseline;
  font-size: 90%;
  font-weight: 500;
  padding: 10px 0;
}
.our-process p {
  text-align: center;
  font-size: 75%;
}
.btn {
  padding: 10px 30px;
  margin-top: 15px;
  border-radius: 200px;
  color: #fff;
  transition: background-color 0.2s, border 0.2s;
}

.btn-about {
  margin-right: 15px;
}

.logo {
  color: #3498db;
  display: flex;
}
.nav-item {
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
}


.nav-item a {
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 300;
  margin-right: 30px;
  font-size: 90%;
  letter-spacing: 0.5px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.5s;
}

.nav-item a:hover,
.nav-item a:active {
  color: #fff;
  border-bottom: 2px solid #3498db;
}

.about-us {
  text-align: center !important;
  margin-bottom: 30px;
}
.about-details {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  padding-top: 20px;
}
.section {
  padding: 80px 0;
}

.section p {
  font-size: 85%;
}

.process {
  background-color: #f4f4f4;
}

.about-img {
  background-image: url(images/coding.jpg);
  background-size: cover;
  padding: 10px;
}

.icon {
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 50%;
  height: 80px;
  width: 80px !important;
  margin-right: 10px;
}
.agile {
  border: 5px solid #3fd1d6;
  background-color: #3fd1d6;
}
.dev {
  border: 5px solid #4773d3;
  background-color: #4773d3;
}
.custom {
  border: 5px solid #ff7070f2;
  background-color: #ff7070f2;
}
.about-card {
  display: flex;
  /* align-items: center; */
  margin-bottom: 30px;
}
.top {
  margin-bottom: 30px;
}

.services {
  background-image: linear-gradient(
      rgba(26, 37, 53, 0.7),
      rgba(10, 28, 49, 0.7)
    ),
    url(images/coding.jpg);
  background-size: cover;
  background-attachment: fixed;
}

.services-card {
  height: 400px;
  background-color: #fff;
  opacity: 0.8;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-top: 30px;
}

.services-icon {
  border: 5px solid #007bff;
  background-color: #007bff;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.footer {
  background-color: #222121;
  position: relative;
}
@keyframes animateIcon {
  from {
    bottom: 5px;
  }
  to {
    bottom: 30px;
  }
}
.footer-icon {
  position: absolute;
  right: 0;
  font-size: 20px;
  bottom: 10px;
  color: #007bff !important;
  animation: animateIcon 1s linear infinite;
}
.footer-icon:hover {
  animation-play-state: paused;
}
.footer-text {
  color: #fff;
  font-size: 80%;
  padding-top: 10px;
  text-align: center;
}

.contact-details {
  margin-top: 30px;
}

.contact-form {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

.form {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.input {
  width: 400px;
  margin-left: 10px;
}
label {
  font-size: 90%;
}

.input:focus {
  outline: none;
}

.location-icon {
  color: #0e75e2;
  font-size: 25px;
  margin-top: 5px;
  margin-right: 10px;
}

#basic-navbar-nav {
  display: block !important;
}

@media(max-width: 600px){
  .nav-item a{
    display: none;
  }
  
}


.form-button {
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

.form-control {
  /* font-size: 90%; */
  font-weight: 300;
}

.heading-primary {
  animation: moveInLeft 4s;
}
.heading-secondary {
  animation: moveInRight 4s;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .contact-info {
    text-align: center;
  }
  .heading-primary {
    font-size: 180%;
  }
  .heading-secondary {
    font-size: 150%;
  }
}